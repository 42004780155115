import { CONSTANTES } from "./constantes";
const FrontBackEndpoint = CONSTANTES.FRONTBACK_ENDPOINT;
const executeForCRUD = (args) => {
  const url = FrontBackEndpoint + "/api/fb/exe4crud";
  const token = localStorage.getItem("token") || "";
  const method = "POST";
  args.UsuarioAdd = 1;
  args.UsuarioUMod = 1;
  args.UsuarioDel = 1;
  return fetch(url, {
    method,
    headers: {
      "Content-type": "application/json",
      "x-token": token,
    },
    body: JSON.stringify(args),
  });
};
const executeAPI = (args) => {
  const url = CONSTANTES.FRONTBACK_ENDPOINT_COTIZA + "/api/" + args.Action;
  const token = localStorage.getItem("token") || "";
  const method = "POST";
  let params = "";
  for (let key in args) {
    params += key + "=" + args[key] + "&";
  }
  params += "UsuarioAdd=1&UsuarioUMod=1&UsuarioDel=1";
  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-token": token,
    },
    body: params,
  });
};

export { executeForCRUD, executeAPI };
