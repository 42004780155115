export const CONSTANTES = {
  FB_APP_ID: "655042801601551",
  FB_WEBHOOK_URL: "https://su-seguro-chat-bot.herokuapp.com",
  // FRONTBACK_ENDPOINT: "https://segunet.herokuapp.com",
  // FRONTBACK_ENDPOINT_COTIZA: "http://cognosys.mx:3001",

  FRONTBACK_ENDPOINT: "https://fb.segunet.com.mx",
  FRONTBACK_ENDPOINT_COTIZA: "https://fb.segunet.com.mx",

  Id_Ramo_VIDA: 1,
  Id_Ramo_GASTOS_MEDICOS: 2,
  Id_Ramo_AUTOS: 3,
  Id_Ramo_RC_MEDICOS: 7,
  Id_Ramo_CASA_HABITACION: 4,
  Id_Cotizacion_Campania_FB: "6",
};
