import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Gmm } from "../components/facebook/Gmm";
import { Autos } from "../components/facebook/Autos";
import { Home } from "../components/home/Home";
import { PublicRoute } from "./PublicRoute";
import { startChecking } from "../actions/auth";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, uid } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking) {
    return <h5>Espere...</h5>;
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/facebook/gmm"
            component={Gmm}
            isAuthenticated={true}
          />
          <PublicRoute
            exact
            path="/facebook/autos"
            component={Autos}
            isAuthenticated={true}
          />
          <PublicRoute exact path="/" component={Home} isAuthenticated={true} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};
