import React, { useState } from "react";
// import { colourOptions, stateOptions, flavourOptions } from "../../docs/data";
import Select from "react-select";

import "./facebook.css";
import { executeAPI, executeForCRUD } from "../../helpers/frontback";
import { fetchSinToken } from "../../helpers/fetch";
import { CONSTANTES } from "../../helpers/constantes";
export const Autos = () => {
  const [arrMarcas, setArrMarcas] = useState([]);
  const [arrTipos, setArrTipos] = useState([]);
  const [arrDescripciones, setArrDescripciones] = useState([]);

  const [modelo, setModelo] = useState(null);
  const [marca, setMarca] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [psid, setPsid] = useState(null);
  const [Id_Cotizacion, setIdCotizacion] = useState(null);

  window.extAsyncInit = function () {
    var isSupported = window.MessengerExtensions.isInExtension();
    // alert(isSupported);
    // the Messenger Extensions JS SDK is done loading
    window.MessengerExtensions.getContext(
      CONSTANTES.FB_APP_ID,
      function success(result) {
        // alert("Success: " + result.psid);
        console.log(result.psid);
        setPsid(result.psid);
      },
      function error(result) {
        // alert(JSON.stringify(result));
      }
    );
    // window.MessengerExtensions.getUserID(
    //   function success(user_ids) {
    //     // User ID was successfully obtained.
    //     psid = user_ids.psid;
    //     // alert(psid);
    //   },
    //   function error(err, errorMessage) {
    //     // Error handling code
    //   }
    // );
  };

  let arrModelos = [];

  const getModelos = async () => {
    for (let i = 2020; i >= 2000; i--) {
      arrModelos.push({ value: i, label: i });
    }
  };
  if (arrModelos.length == 0) getModelos();

  const onModelChange = async (event) => {
    setModelo(event.value);

    let args = {
      ClassName: "Marcas_X_Modelo",
      Action: "Combo",
      Modelo: event.value,
      db: "Autonet",
    };
    const resp = await executeForCRUD(args);
    const body = await resp.json();
    console.log(body);

    setArrMarcas(body.data);
  };

  const onMarcaChange = async (event) => {
    setMarca(event.Key);
    console.log(event);
    let args = {
      ClassName: "Tipos_X_Marca_X_Modelo",
      Action: "Combo",
      Modelo: modelo,
      Id_Marca: event.Key,
      db: "Autonet",
    };
    console.log(args);

    const resp = await executeForCRUD(args);
    const body = await resp.json();
    setArrTipos(body.data);
  };
  const onTiposChange = async (event) => {
    setTipo(event.Key);
    console.log(event);
    let args = {
      ClassName: "Descripciones_X_Marca_X_Tipo_X_Modelo",
      Action: "Combo",
      Modelo: modelo,
      Id_Marca: marca,
      Id_Tipo: event.Key,
      db: "Autonet",
    };
    console.log(args);

    const resp = await executeForCRUD(args);
    const body = await resp.json();
    setArrDescripciones(body.data);
  };

  const onDescripcionesChange = async (event) => {
    setDescripcion(event.Key);
  };
  const sendData = async () => {
    let data = {
      psid: psid, //3624768670950607,
      tipo: tipo,
      modelo: modelo,
      descripcion: descripcion,
      marca: marca,
    };

    let endpoint = "messenger/webhook/snippets";

    const respWebHook = await fetchSinToken(endpoint, data, "POST");

    window.MessengerExtensions.requestCloseBrowser(
      function success() {
        console.log("CERRADO");
      },
      function error(err) {
        console.log(err);
      }
    );
  };
  return (
    <div className="container login-container">
      <div className="row">
        <div className="col-md-6 login-form-1">
          <div className="title">¿Qué vehículo quieres asegurar?</div>
          <div className="combo">
            <label>Modelo</label>
            <Select onChange={onModelChange} options={arrModelos} />
          </div>
          <div className="combo">
            <label>Marca</label>
            <Select
              onChange={onMarcaChange}
              isDisabled={arrMarcas.length === 0}
              options={arrMarcas}
            />
          </div>

          <div className="combo">
            <label>Tipo</label>
            <Select
              onChange={onTiposChange}
              isDisabled={arrTipos.length === 0}
              options={arrTipos}
            />
          </div>
          <div className="combo">
            <label>Descricpión</label>
            <Select
              onChange={onDescripcionesChange}
              isDisabled={arrDescripciones.length === 0}
              options={arrDescripciones}
            />
          </div>
          <div className="combo">
            <button onClick={sendData} className="btn btnSubmit">
              Cotizar Seguro
            </button>
          </div>
        </div>
      </div>
      <a className="link" href="https://www.suseguro.com.mx" target="_blank">
        www.suseguro.com.mx
      </a>
    </div>
  );
};
